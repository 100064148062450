import React from "react"
import { Link, graphql } from "gatsby"

import { MHPageProps } from "../types"
import SEO from "../components/seo"
import {
  filterExpiredStories,
  Story,
  StorySlide,
  storyToSlide,
} from "../components/Stories"
import Lightbox from "../components/Lightbox"

const StoriesIndex: React.FC<MHPageProps> = (props) => {
  const slides: StorySlide[] = React.useMemo(() => {
    return props.data.stories.nodes.reduce((o, i) => {
      if (filterExpiredStories(i.frontmatter))
        o.push(storyToSlide(i.frontmatter))
      return o
    }, [])
  }, [props.data.stories])

  const [index, setIndex] = React.useState<number | null>(null)

  const heading = "Casual photography and cinematography"

  return (
    <>
      <SEO title="Stories" description={heading} />
      <header className="oneColumn">
        <div className="n-spacing-bottom-medium n-text-centered">
          <h1 className="n-vspacing-small">Stories</h1>
          <p>
            {heading}. <Link to="/posts/stories">Read more…</Link>
          </p>
        </div>

        <div className="g-popout n-vspacing-medium">
          <ul className="g-image-gallery">
            {slides.map(({ story }, i) => (
              <Story
                key={story.external_id ?? story.date}
                story={story}
                index={i}
                handleOpen={setIndex}
              />
            ))}
          </ul>
        </div>

        <div className="n-text-centered">
          For info about my gear, check my{" "}
          <Link className="g-link" to="/uses">
            /uses
          </Link>{" "}
          page.
        </div>

        <React.Suspense>
          {typeof index === "number" && (
            <Lightbox
              index={index}
              slides={slides}
              close={() => setIndex(null)}
              open
            />
          )}
        </React.Suspense>
      </header>
    </>
  )
}

export default StoriesIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    stories: allMdx(
      filter: { fields: { contentType: { eq: "stories" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          orientation
          blurhash
          alt
          expires
          external_id
          date
          title
          url {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          preview {
            childImageSharp {
              gatsbyImageData(
                # When masonry works, we can disable this
                aspectRatio: 1
                layout: FIXED
                height: 450
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`
