/** @format */
import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import { StoryEntry } from "../../fetchStories"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { Slide } from "yet-another-react-lightbox"
import { isAfter } from "date-fns"

const Lightbox = React.lazy(() => import("./Lightbox"))

type clickHandler = (index: number) => void

export type StorySlide = Slide & { story: StoryEntryFromGraphQL }

export const storyToSlide = (story: StoryEntryFromGraphQL): StorySlide => {
  const isPhoto = !!story.url.childImageSharp
  return isPhoto
    ? {
        src: getSrc(story.url)!,
        title: story.title,
        story,
      }
    : {
        type: "video",
        width: story.orientation === "landscape" ? 1920 : 1080,
        title: story.title,
        height: story.orientation === "landscape" ? 1080 : 1920,
        poster: getSrc(story.preview)!,
        story,
        autoPlay: true,
        sources: [
          {
            src: story.url.publicURL,
            type: "video/mp4",
          },
        ],
      }
}

export const filterExpiredStories = (story: StoryEntryFromGraphQL): boolean => {
  console.log(story)
  return story.expires ? isAfter(new Date(story.expires), new Date()) : true
}

export const Story: React.FC<{
  story: StoryEntryFromGraphQL
  index: number
  className?: string
  handleOpen: clickHandler
}> = (props) => {
  const story = props.story
  const isPhoto = !!story.url.childImageSharp

  return (
    <li className={props.className}>
      <a
        href={isPhoto ? getSrc(story.url) : story.url.publicURL}
        target="_blank"
        className="db"
        onClick={(e) => {
          e.preventDefault()
          props.handleOpen(props.index)
        }}
      >
        <GatsbyImage
          title={story.title}
          style={{ width: "100%", height: "100%", aspectRatio: "1" }}
          alt={story.alt ?? "image without description"}
          image={getImage(story.preview)!}
          className="shadow w-full"
        />
      </a>
    </li>
  )
}
export interface StoryEntryFromGraphQL
  extends Omit<StoryEntry, "url" | "preview"> {
  url: FileNode & { publicURL: string }
  preview: FileNode
  // url: { childImageSharp?: IGatsbyImageDataParent<Node>; publicURL: string }
  // preview: { childImageSharp: IGatsbyImageDataParent<Node> }
}

export const Stories = React.memo((props) => {
  const [index, setIndex] = React.useState<number | null>(null)
  const stories: StoryEntryFromGraphQL[] = useStaticQuery(
    graphql`
      query {
        stories: allMdx(
          filter: { fields: { contentType: { eq: "stories" } } }
          sort: { fields: frontmatter___date, order: DESC }
          limit: 6
        ) {
          nodes {
            frontmatter {
              orientation
              blurhash
              alt
              external_id
              date
              expires
              title
              url {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
              preview {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FIXED
                    height: 150
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    `
  )
    .stories.nodes.map((i) => i.frontmatter)
    .filter(filterExpiredStories)

  const slides = React.useMemo(() => stories.map(storyToSlide), [])
  return (
    <>
      <ul
        className={
          "flex n-frame-smaller nowrap overflow-hidden w-full fade-out"
        }
      >
        {stories.map((story, i) => {
          return (
            <Story
              className="n-spacing-smaller"
              story={story}
              key={story.external_id ?? story.date ?? i}
              handleOpen={setIndex}
              index={i}
            />
          )
        })}
      </ul>
      <React.Suspense>
        {typeof index === "number" && (
          <Lightbox
            index={index}
            slides={slides}
            close={() => setIndex(null)}
            open
          />
        )}
      </React.Suspense>
    </>
  )
})
